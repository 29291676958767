<template>
  <ClientOnly>
    <UiDrawer
      :open="drawer.open"
      :side="drawer.position"
      v-bind="drawer.props"
      :variant="drawer.props?.variant || 'default'"
      @close="drawer.open = false"
    >
      <KeepAlive>
        <component
          :is="drawer.component"
          v-bind="drawer.data"
          :key="drawer?.key"
          @close="drawer.open = false"
        />
      </KeepAlive>
    </UiDrawer>
  </ClientOnly>
</template>

<script lang="ts" setup>
import UiDrawer from '@ui/components/UiDrawer/UiDrawer.vue'
import { useDrawer } from './useDrawer'
import { ClientOnly } from '#components'

const { drawer } = useDrawer()
</script>
